import * as React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components";
import Theme from "./src/themes/theme";
import { fonts } from "./static/fonts/";
import DownloadIcon from "./static/images/button-download.svg";
import mobilelightburger from "./static/images/lightnav.svg";
import { device } from "./src/components/Helpers";

const GlobalStyles = createGlobalStyle`
    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    .lightmodehamburger{
        display: none;
    }

    .mobileHide{
        display:block;
        @media ${device.mobile} {
            display:none;
         }  
      }

      .mobileShow{
        display:none;
        @media ${device.mobile} {
            display:block;
          }
    }

    ${fonts}

    html,
    body {
        background-color: ${props => props.theme.colors.brand_purple_00} !important;
        color: ${props => props.theme.colors.brand_gray_03}!important;
        font-family: ${props => props.theme.fonts.main}!important;
        font-size: 16px!important;
        line-height: 19px!important;
        height: 100%;
        min-width: 320px!important;

        @media ${device.mobile} {
          font-size: 12px !important;
        }
        &.lightmode{
            background-color: #fff!important;
            color: ${props => props.theme.colors.brand_purple_00}!important;
            #important-safety-information{
                p {
                    color: ${props => props.theme.colors.brand_gray_03}!important;
                }
            }
            
            #patient-link {
                border: 1px solid ${props => props.theme.colors.brand_orange_00};
            }
        }
    }

    select, input{
        font-family: ${props => props.theme.fonts.main}!important;
    }

    .academybanner{
        display: none;
    }

    body {
        opacity: 1;

        &.loaded {
            opacity: 1;
            transition: opacity .1s 0s linear;
        }
        &.lightmode{
            nav > ul > li > a{
                @media ${device.desktop_xlgmx}  {
                    color: #fff!important;
                }
            }
            .hamburgermenu{
                display: none;
            }
            .lightmodehamburger{
                @media ${device.desktop_xlgmx}  {
                    display: block;
                }
            }
            #for-patients-interstitial-container{
                color: rgb(68, 0, 153) !important;
            }
            header{
                #mobile-menu{
                    // @media ${device.desktop_xlgmx}  {
                    //     background: url(${mobilelightburger}) no-repeat;
                    //     background-size: contain;
                    // }
                    // & > img{
                    //     @media ${device.desktop_xlgmx}  {
                    //         visibility: hidden;
                    //     }
                    // }

                    // &.open{
                    //     & > img{
                    //         @media ${device.desktop_xlgmx}  {
                    //             visibility: hidden;
                    //         }
                    //     }
                        
                    // }
                }
                &::before{
                    @media ${device.desktop_xlgmx}  {
                        background:none;
                    }
                    @media ${device.desktop}{
                        background:none;
                    }
                    @media ${device.desktop_lg}{
                        background: none;
                    }
                }
                &::after{
                    @media ${device.desktop_xlgmx}  {
                        background:none;
                    }
                    @media ${device.desktop}{
                        background:none;
                    }
                    @media ${device.desktop_lg}{
                        background: none;
                    }
                }
                background-color: #fff;
                a{
                    color: ${props => props.theme.colors.brand_purple_00}!important;
                    &.btn-1{
                        color: ${props => props.theme.colors.white}!important;
                    }
                    &.mobilesubnavitem{
                        @media ${device.desktop_xlgmx}  {
                            color: ${props => props.theme.colors.white}!important;
                        }
                    }
                }
                .roundedges{
                    border-color: #fff!important;
                }
                nav > ul > li:not(.active):hover{
                    color: ${props => props.theme.colors.white}!important;
                    ul{
                        color: ${props => props.theme.colors.white}!important;
                        li{
                            color: ${props => props.theme.colors.white}!important;
                            a,
                            a:hover,
                            a:focus{
                                color: ${props => props.theme.colors.white}!important;
                            }
                            ul{
                                & > li{
                                    & >  a,
                                    & > a:hover,
                                    & > a:focus{
                                        color: ${props => props.theme.colors.white}!important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .academybanner{
                display: block;
                background-color: #440099;
                color: #fff;
                margin-top: 7rem;
                padding-top: 1rem;
                padding-left: 1rem;
                padding-bottom: 1rem;
                position: relative;
                width: 100%;
                @media ${device.mobile}  {
                    margin-top: 4rem;
                    font-size: 2rem;
                }
                em{
                    max-width: 1170px !important;
                    min-width: 300px !important;
                    width: 90% !important;
                    display: block;
                    position: relative;
                    margin: 0 auto;
                    font-size: 28px;
                }
            }
            #important-safety-information .isi-title-bar{
                h2, h3{
                    color: ${props => props.theme.colors.brand_purple_00}!important;
                }
            }
            footer{
                padding-top: 0;
                color: ${props => props.theme.colors.brand_purple_00}!important;
                #insmed-logo{
                    width: 100px;
                    left: 1.25rem;
                    img{
                        width: 100%;
                    }
                }
                a{
                    color: ${props => props.theme.colors.brand_purple_00}!important;
                    &.btn-1{
                        color: ${props => props.theme.colors.white}!important;
                    }
                }
                p{
                    color: #000!important;
                    ${'' /* &.tiny{
                        @media ${device.desktop_xlgmx}  {
                            color: ${props => props.theme.colors.brand_purple_00}!important;
                        }
                    } */}
                }
            }
        }
    }

    html.landscape {

        .image-modal {
            margin-top: 6%;

            .slick-track > .slick-slide > div {
                overflow-y: scroll;
                height: 74vh;
            }
        }

        .mobile-scan-modal-content {
            background-position-x: -1rem;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            margin-top: 3%;
            max-height: 85vh;
            position: relative;
            width: 67vw;
        }

        .rotate-device {
            display: none!important;
        }

        transition: none!important;
    }

    html.portrait {

        .mobile-scan-modal-content {
            background-position-x: 42%;
            background-size: 104%;
            height: auto;
            margin-top: 25%;
            padding-bottom: 63%;
            width: 90%;
        }

        .mobile-scan-modal-close {
            right: 1%;
        }
    }

    html.menu {
        @media ${device.mobile} {
            overflow: hidden !important;
        }
    }

    html.locked {
        overflow: hidden;
    }

    html {
        &.isi-tray-open {
            overflow: hidden!important;

            header {
                z-index: 1;
            }

            #isi-tray {
                height: 82.5%;

                @media ${device.desktop_xlgmx} { height: 75.5vh; }
                @media ${device.mobile} { height: 80.5vh; }

                &> .isi-tray-bg {
                    position: relative;
                    background: ${props => props.theme.colors.brand_purple_02};
                    height: 100%;
                    left: 0;
                    opacity: 1;
                    padding: 30rem;
                    position: fixed;
                    top: 0;
                    width: 100%;
                    z-index: 0;

                    &::after {
                      position: absolute;
                      left: 0;
                      height: 100%;
                      width: 20%;
                    }

                    &::before {
                      position: absolute;
                      right: 0;
                      width: 20%;
                      height: 100%;
                    }
                }

                .caret {
                    transform: rotate(90deg);
                }
            }

            [data-component="isi"] > div:nth-of-type(2) {
                overflow-y: scroll;
                height: 80vh;

                @media ${device.desktop_xlgmx} {
                    height: 88vh;
                    padding-bottom: 10rem;
                }
                @media ${device.smmobile}{
                    padding-bottom: 5rem;
                }
            }
        }

        &.isi-tray-collapsed {
            #isi-tray {
                height: 2.5rem;
            }
        }

        &.isi-tray-collapsed.mini-isi {
            #isi-tray {
                height: 82.5%;
            }
        }

        &.lightmode {

            #isi-inside-tray [data-component="isi"] p {
                color: #000;
            }

            footer {
                ul.wrapper li:not(:last-child) {
                    @media screen and (max-width: 767px) {
                        border-right: 2px solid ${props => props.theme.colors.brand_purple_00};
                    }
                }
            }
        }
    }

    #gatsby-focus-wrapper {
        background-color: inherit !important;
    }

    #skiptocontent a {
        padding: 6px;
        position: absolute;
        top: -40px;
        left: 0px;
        color: white;
        border-bottom-right-radius: 0.35rem;
        background: #d34604;
        -webkit-transition: top 1s ease-out;
        transition: top 1s ease-out;
        z-index: 100;

        &:focus {
            position: absolute;
            left: 0px;
            top: 0px;
            outline: none;
            -webkit-transition: top .1s ease-in;
            transition: top .1s ease-in;
        }
    }

    #hero-container {
        background-color: white;
        background-image: linear-gradient(to bottom, #ece5f4 1%, #fff 66%);
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        padding-right: 1.25rem;
        padding-top: 1.5rem;
        position: relative;
        text-align: center;
        &> img {
            margin: 0 auto;
            max-width: 100%;
            width: 100%;
        }
    }

    a {
        color: ${props => props.theme.colors.brand_purple_00};
        text-decoration: none;

        &.sup-underline-fix {
            sup {
            display: inline-block;
            border-bottom: 1px solid ${props => props.theme.colors.brand_purple_00};
            padding-bottom: .95rem
          }
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    a,
    span,
    button {
        &.btn-1,
        &.btn-2,
        &.btn-3,
        &.btn-6 {
            align-items: center;
            background-color: ${props => props.theme.colors.brand_orange_00};
            border-radius: .35rem;
            color: white;
            display: flex;
            font-size: 1.125rem;
            font-weight: bold;
            height: 3rem;
            justify-content: flex-start;
            line-height: 2rem;
            padding: 0 44px 0 24px;
            position: relative;
            text-decoration: none;
            font-family: ${props => props.theme.fonts.main}!important;
            border: none;
            @media ${device.mobile} {
                font-size:1.166rem!important;
                height: 2.5rem;
                padding: 0 10% 0 5%;
            }
        }

        &.btn-1 {
            &> img {
                height: 100%;
                width: .5rem;
                position: absolute;
                right: 0;
                top: 0;
                margin-right: .75rem;
            }
            &:hover img{
                right: -2px;
            }

            &.disabled {
            color: #a7a7a7;
            background-color: #cdcdcd;
            }
        }

        &.btn-2 {
            font-size: 1rem;
            display: flex;
            flex-direction: column;
            line-height: 1rem;
            padding: .7rem;
            padding-right: 3rem;
            height: auto;

            &::after {
                content: '+';
                border-left: 2px solid ${props => props.theme.colors.white};
                font-size: 3rem;
                font-weight: normal;
                height: 100%;
                line-height: 2.75rem;
                padding-left: .5rem;
                position: absolute;
                right: .5rem;
                top: 0;
            }
        }

        &.btn-3 {
            &::after {
                position: absolute;
                content: "";
                background: url(${DownloadIcon}) no-repeat;
                width: 2rem;
                height: 2rem;
                right: .5rem;
            }
        }

        &.btn-4 {
            background-color: ${props => props.theme.colors.white};
            border-radius: .35rem;
            color: ${props => props.theme.colors.brand_purple_00}!important;
            display: inline-block;
            font-weight: 600;
            line-height: .9rem;
            padding: .5rem 2.25rem .5rem .6rem;
            position: relative;
            font-size: .75rem!important;

            &::after {
                content: '';
                background-image: url("../images/new-window.svg");
                background-repeat: no-repeat;
                background-size: contain;
                margin: .5rem;
                padding: .45rem;
                position: absolute;
                right: 0;
                top: 0;
            }

            &.download::after {
                background-image: url("../images/icon-download.svg");
                padding: .6rem;
                margin-top: 10px;
                @media ${device.tabletxl} {
                  margin-top: 14px;
                }
            }

            &.arrow{
                min-width: 175px;
                margin-top: .5rem;
            }

            &.arrow::after{
                background-image: url("../images/orangerightarrow.svg");
                padding: .6rem;
                margin-top: 10px;
                @media ${device.tabletxl} {
                  margin-top: 14px;
                }
            }

            &:hover{
                &::after {
                    right: -7px;
                }
            }
        }

        &.btn-5 {
            background-color: ${props => props.theme.colors.brand_purple_00};
            border-radius: .35rem;
            color: ${props => props.theme.colors.white};
            display: inline-block;
            font-size: 1.33333rem;
            font-weight: bold;
            letter-spacing: 0;
            padding: 1rem;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }

            &.active {
                border: 2px solid ${props => props.theme.colors.brand_orange_00};
                background-color: ${props => props.theme.colors.white};
                color: ${props => props.theme.colors.brand_orange_00};
            }
        }
    }

    h1, .h1 {
        color: ${props => props.theme.colors.brand_purple_00};
        font-size: 1.75rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;

        @media ${device.mobile} {
            font-size: 1.91667rem;
            line-height: 2rem;
        }

        .highlight {
            color: ${props => props.theme.colors.brand_orange_00};
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: -.0075rem;
            line-height: 2rem;
            text-transform: uppercase;

            @media ${device.mobile} {
                display: inline-block;
                line-height: 1.25rem;
                margin-bottom: .4167rem;
            }
        }

        sup {
            font-size: 1rem;
        }
    }

    h2, .h2 {
        color: ${props => props.theme.colors.brand_purple_00};
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 600;
        letter-spacing: 0;

        @media ${device.mobile} {
            font-size: 1.5833rem;
            line-height: 1.9166rem;
            word-spacing: .15rem;
        }

        sup {
            font-size: .75rem;
        }

        &.bottom-spacer {
          margin-bottom: 2.5rem;
        }
    }

    h3 {
        color: ${props => props.theme.colors.brand_orange_00};
        font-size: 1rem;
        line-height: 2rem;

        @media ${device.mobile} {
            font-size: 1.25rem;
        }
    }

    sup {
      &.footnote-symbol {
        font-family: "Helvetica Neue", sans-serif;
      }
    }

    main {
        background-color: ${props => props.theme.colors.white};
        border-bottom-left-radius: 0!important;
        border-bottom-right-radius: 0!important;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        overflow: hidden;
        padding-bottom: 4rem!important;
        padding-top: 8.5rem!important;

        @media ${device.mobile} {
            padding-top: 12.5rem!important;
        }
    }

    ol li::marker {
        color: ${props => props.theme.colors.brand_orange_00};
        font-weight: bold;
    }

    p {
        letter-spacing: 0;
        position: relative;

        @media ${device.mobile} {
            font-size: 1.33333rem;
        }

        strong > a {
          text-decoration: underline;
        }
    }

    .definitions > p {
        font-size: 12px;
        @media ${device.mobile} {
            line-height: 1.25rem;
        }
    }

    .references {
        @media ${device.mobile} {
            line-height: 1.15rem;
        }
    }

    .slick-track { margin: 0 auto; }

    ul {
        padding-left: 1rem;

      &.slick-dots {
        li {
          &::before {
            content: "";
          }

          button:before {
            color: transparent !important;
            border: 1px solid ${props => props.theme.colors.brand_orange_00};
            width: 10px;
            height: 10px;
            border-radius: 100%;
            opacity: 100%;
          }
        }

        li.slick-active button:before {
          background-color: #E35203 !important;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          opacity: 100%;
        }
      }

      li {
          list-style-type: none!important;
          position: relative;
          margin-bottom: .75rem;

            &:before {
                content: '•';
                color: ${props => props.theme.colors.brand_orange_00};
                font-size: 2rem;
                margin-left: -1.15rem;
                position: absolute;
                left: 0;
                top: -3px;

                @media ${device.mobile} {
                    font-size: 3rem;
                    line-height: 1.15rem;
                    top: -2px;
                }
            }

            > ul {
              li {
                &:before {
                    content: '-';
                    color: ${props => props.theme.colors.brand_orange_00};
                    font-size: 1.5rem;
                    margin-left: -1.15rem;
                    position: absolute;
                    left: 0;
                    top: -3px;
                    @media ${device.mobile} {
                        top: 0px;
                    }
                }
              }
            }
        }
    }

    section {
        margin: 6rem auto;

        @media ${device.tablet}     { margin: 8rem auto; }
        @media ${device.desktop}    { margin: 8rem auto; }
        @media ${device.desktop_lg} { margin: 6rem 0; }
        @media screen and (min-width: 1200px) and (max-width: 1280px) {
            margin: 8rem 0;
        }
        @media ${device.mobile} {
            margin: 5rem auto 6rem;
        }
        h1, .h1 {
            margin-bottom: 1.25rem;

            @media ${device.mobile} {
                margin-bottom: 0;
            }
        }
    }

    small {
        font-size: .875rem;
    }

    sub,
    sup {
        line-height: 0 !important;
        font-size: x-small;
    }

    [data-component="callout"] {
        border-radius: .35rem;
        padding: 2rem;

        &.color-purple {
            color: ${props => props.theme.colors.brand_purple_00};
        }

        &.green {
            border: 2px solid ${props => props.theme.colors.brand_green_00};
        }

        &.purp {
            background-color: ${props => props.theme.colors.brand_purple_01};
        }

        &.white {
            border: 2px solid ${props => props.theme.colors.brand_orange_00};
            margin: 0 1rem;
            padding: 2rem 2rem .5rem 2rem;

            @media ${device.mobile} {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        h2 {
          > span {
            &.gray-header {
              color: ${props => props.theme.colors.brand_gray_03};
            }
          }
        }
      p {
        > span {
          &.purple {
            color: ${props => props.theme.colors.brand_purple_00};
          }
        }
      }
    }

    [data-component="tabs"] {

        &> ul {
            align-items: flex-end;
            display: flex;
            padding: 0;

            &> li {
                background-color: ${props => props.theme.colors.brand_purple_00};
                border-left: 2px solid ${props => props.theme.colors.brand_purple_00};
                border-right: 2px solid ${props => props.theme.colors.brand_purple_00};
                border-top-left-radius: .25rem;
                border-top-right-radius: .25rem;
                border-top: 2px solid ${props => props.theme.colors.brand_purple_00};
                color: ${props => props.theme.colors.white};
                cursor: pointer;
                font-size: 1.25rem;
                font-weight: bold;
                line-height: 3rem;
                padding: 0 .85rem;
                margin-bottom: 0;

                &::before {
                    content: none;
                }

                &.active {
                    background-color: ${props => props.theme.colors.white};
                    color: ${props => props.theme.colors.brand_purple_00};
                    cursor: default;
                }
            }
        }

        &> div {
            border: 2px solid ${props => props.theme.colors.brand_purple_00};
            border-radius: .35rem;
            border-top-left-radius: 0;
            margin-top: -.15rem;
            padding: 3rem 4rem 3.75rem 4rem;

            [data-tab-content] {
                display: none;

                &.active {
                    display: block;
                }

                p {
                  a {
                    text-decoration: underline;
                  }
                }
            }
        }

    }

    .hang {
        position: absolute;
        top: 0;
        left: -6px;

        &.tight {
            left: -3px;
            top: 3px;
        }
    }

    .hidden {
        display: none;
    }

    .md-container {
        padding-top: 0;
    }

    .seo {
        position: absolute !important;
        left: -999999px!important;
        top: -999999px!important;
    }

    .table-row     { display: table-row; }
    .table-heading { display: table-header-group;}
    .table-body    { display: table-row-group; }

    .table-cell,
    .table-col-head { display: table-cell; }

    .table-00 {
        border-collapse: collapse;
        border-radius: .15rem;
        // any larger radius and it cuts off the borders
        // border-radius: .35rem;
        height: 200%;
        overflow: hidden;
        position: relative;
        text-align: left;
        width: 100%;

        &:after {
            content: '';
            border-top: 2px solid ${props => props.theme.colors.brand_purple_00};
            border-right: 2px solid ${props => props.theme.colors.brand_purple_00};
            border-left: 2px solid ${props => props.theme.colors.brand_purple_00};
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }


        .table-body > .table-row:last-of-type{
            content: '';
            border-bottom: 2px solid ${props => props.theme.colors.brand_purple_00};
            left: 0;
            top: 0;
            width: 100%;
        }

        .table-cell,
        .table-col-head {
            padding: 3px 2px;
        }

        .table-cell {
            line-height: 2rem;
            padding: 0 1rem;
            width: 33%;

            &:first-child {
                /* border-left: 1px solid ${props => props.theme.colors.brand_purple_00}; */
            }
            &:nth-child(2) {
                border-left: 2px solid ${props => props.theme.colors.brand_purple_00};
                border-right: 2px solid ${props => props.theme.colors.brand_purple_00};
            }
            &:last-child {
                /* border-right: 1px solid ${props => props.theme.colors.brand_purple_00}; */
            }
        }

        .table-row:nth-child(even) {
            background: ${props => props.theme.colors.brand_purple_01};
        }

        .table-heading {
            background: ${props => props.theme.colors.brand_purple_00};

            .table-col-head {
                border-left: 2px solid ${props => props.theme.colors.white};
                color: ${props => props.theme.colors.white};
                font-weight: bold;
                line-height: 3rem;
                text-align: center;
                width: 33%;

                &:first-child {
                    border-left: none;
                    border-top-left-radius: .25rem;
                }

                &:last-child {
                    border-top-right-radius: .25rem;
                }
            }
        }

        display: table;
    }

    .tiny {
        font-size: .75rem;
        line-height: 1.1;
        @media ${device.mobile} {
            font-size:1.0833rem;
        }
        &.chartcopy{
            margin-top: 1.5rem!important;
        }
        a {
          text-decoration: underline;
        }
    }

    .wrapper,
    .form-wrapper,
    .hero-wrapper {
        border-radius: 5px;
        margin: 0 auto;
        max-width: 1170px!important;
        min-width: 300px!important;
        width: 90%!important;
        padding: 0 1.25rem;

        @media ${device.mobile} {
            padding: 0 1rem;
        }
    }

    .form-wrapper {
      padding: 0;
    }

    .roundedges {
        border-bottom: none!important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: 5px solid #440099;
        height: 20px;
        left: 0px;
        margin: 0 auto;
        max-width: 1180px!important;
        min-width: 300px!important;
        position: relative;
        top: 4rem;
        width: 90.8%!important;

        @media ${device.desktop_xlgmx} {
            display: none;
        }

        @media ${device.mobile} { top: 8rem; }
        /* @media ${device.desktop_xlgmx} { top: 9rem;} */

        @media only screen and (min-width: 300px) and (max-width: 374px) {
            /* bottom: -8.25rem !important; */
            width: 96.7%!important;
            min-width: auto!important;
        }

        @media only screen and (min-width: 375px) and (max-width: 414px) {
            /* bottom: -8.2rem !important; */
            width: 92.5%!important;
        }

        @media only screen and (min-width: 415px) and (max-width: 570px) {
            /* bottom: -9rem !important; */
            width: 91.8%!important;
        }

        @media only screen and (min-width: 571px) and (max-width: 767px) {
            /* bottom: -9rem !important; */
            width: 91.5%!important;
        }

        @media only screen and (min-width: 768px) and (max-width: 810px) {
            width: 91.3%!important;
            /* bottom: -144px!important; */
        }

        @media only screen and (min-width: 811px) and (max-width: 870px) {
            width: 91.2%!important;
            /* bottom: -144px!important; */
        }

        @media only screen and (min-width: 871px) and (max-width: 991px) {
            width: 91.1%!important;
            /* bottom: -144px!important; */
        }

        @media only screen and (min-width: 991px) and (max-width: 1130px) {
            width: 91%!important;
            /* bottom: -144px!important; */
        }

        @media only screen and (min-width: 1130px) and (max-width: 1265px) {
            /* bottom: -144px!important; */
        }
    }

    .secondary .roundedges {
        top: 1rem;

        @media ${device.desktop_xlgmx} {
            top: 13rem;
        }

        @media only screen and (min-width: 300px) and (max-width: 767px) {
            /* bottom: -12.2rem !important; */
        }

        @media only screen and (min-width: 415px) and (max-width: 767px) {
            /* bottom: -13rem !important; */
        }

        @media only screen and (min-width: 768px) and (max-width: 1280px) {
            /* bottom: -13rem !important; */
        }
    }

    .centered {
      text-align: center;
    }

    .desktoponly{
      display: block;
        @media ${device.mobile} {
          display: none !important;
        }
    }

    .abovedesktoponly{
        display: block;
        @media ${device.desktop_xlgmx}{
            display: none!important;
        }
    }

    .desktoponlyinline{
        display: inline-block;
          @media ${device.mobile} {
            display: none !important;
          }
      }

    .mobileonly{
        display: none !important;
        @media ${device.mobile} {
            display: block!important;
        }
    }

    #important-safety-information-toggle-container {
      >div { padding-top: .0938rem; cursor: pointer; }

      @media ${device.mobile} {
        line-height: .9rem;
      }
    }

.ar-popup-button, .ar-popup-button p strong {
    font-family: 'Montserrat';
}

.content-wrapper {
    h3 {
      font-family: 'Montserrat';
    }
    p{
        font-family: 'Montserrat';
    }
}

.ar-popup-button,
.ar-popup-button p strong {
    font-family: 'Montserrat';
}

.trigger {
    ${props => props.isOpen ? "padding: 0" : "padding-left: 0"};
    background-color: ${props => props.theme.colors.brand_orange_00};
    border-radius: 100%;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.85em;
    font-weight: bold;
    height: 1.25rem;
    left: 49.35%;
    line-height: .8rem;
    position: absolute;
    text-align: center;
    top: -0.6rem;
    width: 1.25rem;
    z-index: 2;                     

    @media ${device.mobile} {
        font-size: 180%;
        height: 1.667rem;
        left: 46.35%;
        width: 1.667rem;
        line-height: 1.20rem;
        top: -0.82rem;
    }
}

.font-helvetica {
    font-family: Arial, Helvetica, sans-serif;
    
    &.super {
        font-size: 6px;
        margin-top: 4.75px;
        position: absolute;
        top: 0;
    }
}

.text14{
    font-size: 14px;
    line-height: 17px;
}
.darktext{
    color: rgb(38, 38, 38);
}

a.underline{
    text-decoration: underline!important;
}

sup.topsup{
    position: relative;
    top: -2px;
}

.marBot1{
    margin-bottom: 1rem!important;
}

.marBot5{
    margin-bottom: .5rem!important;
}

.mobilemarbot1{
    @media ${device.mobile} {
        margin-bottom: 1rem!important;
    }
}

.mobilemarbot5{
    @media ${device.mobile} {
        margin-bottom: .5rem!important;
    }
}

.nowrap {
    white-space: nowrap !important;
}


`;

const addScript = url => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
}

export const wrapPageElement = ({ element }) => {
    if (typeof window !== 'undefined') {
        addScript("//cdn.8thwall.com/web/iframe/iframe.js");
    }

    return (
        <ThemeProvider theme={Theme}> {/* Assuming `Theme` is defined elsewhere */}
            <GlobalStyles />
            {element}
        </ThemeProvider>
    );
};

export const onRouteUpdate = ({ location, prevLocation }) => {
    let navigation;
    
    setTimeout(() => {
        navigation = document.getElementById('navigation');
        if (!navigation.children[1]) {
            // scroll window to top
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, 200);
};